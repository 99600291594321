<template>
  <div
    class="AccountSharedLayout"
    :class="{ useMobileLayout }">
    <Media
      :query="{ maxWidth: 600 }"
      @media-enter="mediaEnter"
      @media-leave="mediaLeave" />
    <div class="spacer" />

    <div class="viewHeader">
      <MemolifeLogo class="memolifeLogo" />
    </div>

    <div
      v-if="showPlan && currentPlan"
      class="box">
      {{ $t('currentPlan', { plan: $t(currentPlan) }) }}
    </div>
    <div
      class="box"
      :class="{ hasSideSlot: $slots['side'] }">
      <portal
        :to="'destination' + unique"
        :disabled="useMobileLayout">
        <!-- NOTE: no spaces around slot tag, so :empty selector can work -->
        <div class="boxTitle">
          <slot name="title" />
        </div>
      </portal>

      <div class="boxMain">
        <portal-target :name="'destination' + unique" />
        <!-- NOTE: no spaces around slot tag, so :empty selector can work -->
        <div class="boxSubtitle">
          <slot name="subtitle" />
        </div>
        <slot name="main">
          [MAIN]
        </slot>
      </div>
      <!-- NOTE: no spaces around slot tag, so :empty selector can work -->
      <div
        class="boxSide"
        :class="{ 'order-top': sideOnTop, 'hide-on-mobile': hideSideOnMobile }">
        <slot name="side" />
      </div>
    </div>

    <div class="viewFooter">
      <slot name="footer" />
      <div
        v-if="showLegal"
        class="legal">
        <span
          v-t="'account.legalDocuments.termsOfUse.label'"
          class="--global-clickable"
          @click="openTermsTest($t('account.legalDocuments.termsOfUse.url'))" />
        |
        <span
          v-t="'account.legalDocuments.privacyPolicy.label'"
          class="--global-clickable"
          @click="openTermsTest($t('account.legalDocuments.privacyPolicy.url'))" />
      </div>

      <div
        v-if="showLanguageSwitch"
        class="languageSwitch">
        <span
          class="languageSwitchLanguage"
          :class="{ active: currentLocale === 'en' }"
          @click="setLocale('en')">
          <svgicon
            class="svg-icon"
            icon="checkmark" />
          <span class="languageSwitchIndicator" />English
        </span>
        <span
          class="languageSwitchLanguage"
          :class="{ active: currentLocale === 'no' }"
          @click="setLocale('no')">
          <svgicon
            class="svg-icon"
            icon="checkmark" />
          <span class="languageSwitchIndicator" />Norsk
        </span>
      </div>
    </div>

    <div class="spacer" />
  </div>
</template>

<translations>
  currentPlan: Your current subscription plan is {plan}
  currentPlan_no: Ditt nåværende abonnement er {plan}
  month: monthly
  month_no: månedlig
  annual: annual
  annual_no: årlig
  free: free forever
  free_no: gratis for alltid
</translations>

<script>
import Media from 'vue-media';
import MemolifeLogo from '@/components/MemolifeLogo';
import SimpleMarkdownViewer from './SimpleMarkdownViewer';
import cbPlans from '../views/AccountCheckoutView/plans';

export default {
    components: { MemolifeLogo, Media },
    props: {
        showLegal: {
            type: Boolean,
            default: false
        },
        showLanguageSwitch: {
            type: Boolean,
            default: false
        },
        showPlan: {
          type: Boolean,
          default: true,
        },
        sideOnTop: {
            type: Boolean,
            default: false
        },
        hideSideOnMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            useMobileLayout: window.innerWidth < 600
        };
    },
    computed: {
        hasSideSlot() {
            return !!this.$slots['side'];
        },
        currentLocale() {
            return this.$store.getters['moduleApp/locale'];
        },
        currentPlan() {
            const isSubscribed = this.$store.getters['moduleAuth/isSubscribed'];
            if (!isSubscribed) {
                return null;
            }
            const planId = this.$store.getters['moduleAuth/plan'];
            const plans = cbPlans.filter(p => p.id === planId);
            if (plans.length > 0) {
                return plans[0].planType;
            }
            return null;
        }
    },
    beforeCreate() {
        this.unique = Date.now();
    },
    methods: {
        setLocale(language) {
            this.$store.dispatch('moduleApp/setLocale', language);
        },
        openTermsTest(url) {
            this.$modal.open({
                target: 'app-modal',
                wrap: true,
                component: SimpleMarkdownViewer,
                props: {
                    url: url,
                    content: 'some **bla bla** content',
                    markdown: true
                }
            });
        },
        mediaEnter() {
            this.useMobileLayout = true;
        },
        mediaLeave() {
            this.useMobileLayout = false;
        }
    }
};
</script>

<style lang="scss" scoped>
$mainPadding: 1em;
$grayLineColor: #eee;

.AccountSharedLayout {
    padding: 1em;

    background: $accounts-background;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.spacer {
    flex: 1;
    min-height: 1em;
}

.viewHeader {
    margin-bottom: 2em;
}
.memolifeLogo {
    font-size: 140%;
}

.box {
    position: relative;
    background-color: white;
    width: 100%;
    box-shadow: 0 0 1em rgba(black, 0.1);
    flex-shrink: 0;
}

.boxTitle {
    text-align: center;
    margin-bottom: 2em;
    /deep/ span {
        font-weight: 600;
        font-size: 140%;
        color: #444; //$grayTextColor-dark;
    }
    &:empty {
        display: none;
    }
}
.boxSubtitle {
    line-height: 1.5em;
    margin-bottom: 2em;

    &:empty {
        display: none;
    }
}
.boxMain {
}
.boxSide:empty {
    display: none;
}

.viewFooter {
    margin-top: 1em;
    text-align: center;
    color: white;
}

.legal {
    color: rgba(white, 0.7);
}

.languageSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.languageSwitchLanguage {
    margin-top: 1em;
    cursor: pointer;
    margin-right: 1em;
    // padding-bottom: 4px;
    // border-bottom: 2px solid transparent;
    color: rgba(white, 0.7);
    display: inline-flex;
    align-items: center;

    .svg-icon {
        $size: 0.5em;
        width: $size;
        height: $size;
        margin-right: 0.3em;
        display: none;
    }

    &.active {
        // border-bottom: 2px solid white;

        // text-decoration: underline;
        .languageSwitchIndicator {
            &::after {
                content: '';
                display: block;
                $size: 6px;
                width: $size;
                height: $size;
                background-color: rgba(white, 0.7);
                border-radius: 50%;
            }
        }
    }
    &:not(.active) {
        .svg-icon {
            display: none;
        }
    }
}
.languageSwitchIndicator {
    $size: 1em;
    display: inline-flex;
    width: $size;
    height: $size;
    border: 1px solid rgba(white, 0.5);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 0.3em;
    // display: none;

    // &::after {
    //   content: "";
    //   display: block;
    //   $size: 8px;
    //   width: $size;
    //   height: $size;
    //   background-color: white;
    //   border-radius: 50%;
    // }
}

@include media('>=600px') {
    .box {
        display: flex;
        padding: $mainPadding 0;
        max-width: 400px;

        &.hasSideSlot {
            max-width: 700px;
        }
    }
    .boxMain {
        flex: 1;
        padding: $mainPadding;
    }
    .boxSide {
        border-left: 1px solid $grayLineColor;
        // padding-left: $mainPadding;
        padding: $mainPadding;
        flex: 1;
    }
}
@include media('<600px') {
    .box {
        padding: $mainPadding;
        display: flex;
        flex-direction: column;
    }

    .boxTitle {
        order: 1;
    }
    .boxSide {
        &.hide-on-mobile {
            display: none;
        }
        &.order-top {
            order: 2;
        }
        &:not(.order-top) {
            order: 4;
            margin-top: 2em;
        }
    }
    .boxMain {
        order: 3;
    }
}
</style>
