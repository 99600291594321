export default [
    {
        id: 'free-forever',
        name: 'Free Forever',
        price: '0',
        planType: 'free',
        period: 'forever'
    },
    {
        id: 'monthly_membership_usd',
        name: 'Monthly',
        price: '29',
        currency: 'usd',
        planType: 'month',
        period: 'month',
        tag: 'standard',
        trialPeriod: 7
    },
    {
        id: 'monthly_membership_nok',
        name: 'Månedlig',
        price: '299',
        currency: 'nok',
        planType: 'month',
        tag: 'standard',
        period: 'måned',
        trialPeriod: 7
    },
    {
        id: 'annual_membership_usd',
        name: 'Annual',
        price: '290',
        currency: 'usd',
        planType: 'annual',
        tag: 'best_value',
        period: 'year',
        youSave: '2months',
        bestValue: true,
        trialPeriod: 7
    },
    {
        id: 'annual_membership_nok',
        name: 'Årlig',
        price: '2990',
        planType: 'annual',
        currency: 'nok',
        tag: 'best_value',
        period: 'år',
        youSave: '2months',
        bestValue: true,
        trialPeriod: 7
    }
];
