<template>
  <AccountSharedLayout class="AccountExpiredView">
    <h1 slot="title">
      AccountExpiredView
    </h1>
  </AccountSharedLayout>
</template>

<script>
import AccountSharedLayout from '../components/AccountSharedLayout';

export default {
    components: { AccountSharedLayout }
};
</script>

<style lang="scss" scoped>
.AccountExpiredView {
}
</style>
